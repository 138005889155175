<template>
  <div>
    <iframe src="http://139.196.170.235:8898/v1/Login/GetQRcode?isIPad=0" title="" 
    style="width: 100%; height: 100%; top: 0;">
    </iframe>
</div>
</template>
<script>
export default {
  name: "RobotStart",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
    };
  },
  
  methods: {
    init() {
      console.log("启动页");
    }

  }
};
</script>
<style lang="less">

</style>
